import React from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ArrowDown from "../../images/ArrowDown";
import useIsMobile from "../../hooks/useIsMobile";
import CustomText from "../CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  entertainmentTitleRoot: {
    paddingTop: 160,
    margin: "0px auto",
    maxWidth: 1600,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    letterSpacing: -2.5,
  },
  entertainment: {
    fontFamily: "Agrandir",
    fontSize: "clamp(38px, 6.250vw, 100px)",
    lineHeight: "120px",
    textDecoration: "underline",
    fontWeight: 900,
  },
  titleMobile: {
    maxWidth: 300,
  },
  arrowMobile: {
    height: 43,
    width: 37,
  },
  entertainmentTitleRootMobile: {
    paddingTop: 70,
    maxWidth: 357,
  },
  titleContainer: {
    flexGrow: 1,
  },
}));

const AboutUsEntertainmentTitle = () => {
  const classes = useStyles();
  const { isMobileClasses, isLandscape } = useIsMobile();
  return (
    <div
      className={isMobileClasses({
        webClass: classes.entertainmentTitleRoot,
        mobileClass: classes.entertainmentTitleRootMobile,
      })}
    >
      <div className={classes.titleContainer}>
        <CustomText
          type="titoloH2Sezioni"
          className={isMobileClasses({
            webClass: classes.text,
            mobileClass: classes.titleMobile,
          })}
        >
          VERTEEGO IS
        </CustomText>
        <CustomText
          variant="h2"
          className={isMobileClasses({
            webClass: clsx(classes.text, classes.entertainment),
            mobileClass: classes.titleMobile,
          })}
        >
          ENTERTAINMENT
        </CustomText>
      </div>
      {!isLandscape && (
        <ArrowDown
          className={isMobileClasses({
            mobileClass: classes.arrowMobile,
          })}
        />
      )}
    </div>
  );
};

export default AboutUsEntertainmentTitle;
