import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "../../Utils/themeProvider";
import AboutUsMainContent from "../Components/AboutUsMainContent";
import BackArea from "../Components/BackArea";
import Collaboration from "../Components/Collaboration";
import Footer from "../Components/Footer";
import MainAppbar from "../Components/MainAppbar";

const aboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ThemeProvider>
      <Helmet>
        <title>Verteego Entertainment – Chi Siamo</title>
        <meta name="description" content="Offriamo una visione d’insieme globale ed omogenea a ciascuno dei nostri partner e clienti, fornendo un supporto costante che si declina attraverso le nostre tre aree commerciali: Entertainment, Advertising e Rent." />
      </Helmet>
      <div >
        <MainAppbar currPage="aboutUs" />
      </div>
      {!isMobile && <BackArea />}
      <AboutUsMainContent />
      <Collaboration />
      <Footer />
    </ThemeProvider>
  );
};

export default aboutUs;
