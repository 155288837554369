import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ArrowDown from "../../../../images/ArrowDown";
import CustomText from "../../../CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  arrowRoot: {
    margin: "0px auto",
    alignContent: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  arrowTitle: {
    lineHeight: "28px",
  },
  arrow: {
    height: 27,
    width: 24,
    marginTop: 24,
    marginBottom: 24,
  },
}));

const ArrowWithTitle = () => {
  const classes = useStyles();
  return (
    <div className={classes.arrowRoot}>
      <CustomText type="payoff2" className={classes.arrowTitle}>
        Try to press a name
      </CustomText>
      <ArrowDown className={classes.arrow} />
    </div>
  );
};

export default ArrowWithTitle;
