import React from "react";
import AboutUsEntertainmentContent from "../AboutUsEntertainmentContent";
import AboutUsEntertainmentTitle from "../AboutUsEntertainmentTitle";


const AboutUsEntertainment = () => {
  return (
    <div>
      <AboutUsEntertainmentTitle />
      <AboutUsEntertainmentContent />
    </div>
  );
};

export default AboutUsEntertainment;