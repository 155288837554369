import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import defaultImg from "../../../../images/logo-sezione-verteego-this-is-us.png";

interface Props {
  getImage?: string;
  hovered: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  photoContainer: {
    border: `12px solid ${theme.colors.entertainment}`,
    boxSizing: "border-box",
    transform: "rotate(4.46deg)",
    maxWidth: 600,
    width: "100%",
    height: 760,
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  photoContainerMobile: {
    marginTop: 24,
    width: "100%",
    height: 450,
  },
}));

const ImageContainer = (props: Props) => {
  const { getImage, hovered } = props;
  const classes = useStyles();
  const { isMobileClasses } = useIsMobile();
  return (
    <div
      className={isMobileClasses({
        webClass: classes.photoContainer,
        mobileClass: classes.photoContainerMobile,
      })}
    >
      <img
        className={classes.img}
        src={getImage || defaultImg}
        alt={`${hovered}`}
      />
    </div>
  );
};

export default ImageContainer;
