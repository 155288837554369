import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useStaticQuery, graphql } from "gatsby";
import useIsMobile from "../../hooks/useIsMobile";
import { StaticImage } from "gatsby-plugin-image";
import CustomText from "../CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    maxWidth: 1600,
    width: "100%",
    margin: "180px auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  wonders: {
    letterSpacing: -2.5,
    textTransform: "uppercase",
    maxWidth: 540,
    margin: "16px 0px",
  },
  description: {
    maxWidth: 650,
    marginBottom: 40,
  },
  itemRoot: {
    display: "flex",
    paddingBottom: 40,
    paddingTop: 40,
    borderBottom: "2px solid #000000",
    maxWidth: 780,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  number: {
    textTransform: "uppercase",
    minWidth: 100,
    marginRight: 32,
  },
  itemContent: {
    maxWidth: 670,
  },
  itemTitle: {
    textTransform: "uppercase",
  },
  img: {
    width: "100%",
    transform: "rotate(-5deg)",
    maxWidth: 700,
    paddingBottom: 40,
  },
  contentContainerMobile: {
    margin: "150px auto",
    maxWidth: 357,
  },
  ourMobile: {
    lineHeight: "24px",
  },
  descriptionMobile: {
    maxWidth: 357,
  },
  imgMobile: {
    width: 292,
    height: 302,
  },
  imgContainerMobile: {
    width: "100%",
    maxWidth: 357,
    justifyContent: "center",
    margin: "0px auto",
  },
  itemRootMobile: {
    maxWidth: 357,
    display: "inherit",
  },
  itemTitleMobile: {
    margin: "16px 0px",
  },
  sticky: {
    position: "sticky",
    top: 24,
  },
}));

const AboutUsEntertainmentContent = () => {
  const classes = useStyles();
  const { isMobile, isMobileClasses } = useIsMobile();
  const items = useStaticQuery(
    graphql`
      query {
        allContentfulEntAboutUsLista {
          edges {
            node {
              title
              subtitle
            }
          }
        }
      }
    `
  );

  return (
    <div
      className={isMobileClasses({
        webClass: classes.contentContainer,
        mobileClass: classes.contentContainerMobile,
      })}
    >
      <div>
        <CustomText
          type="payoff"
          className={isMobileClasses({
            mobileClass: classes.ourMobile,
          })}
        >
          THIS IS OUR
        </CustomText>
        <CustomText
          type="titoloH2Sezioni"
          className={isMobileClasses({
            webClass: classes.wonders,
          })}
        >
          open your eyes to wonders
        </CustomText>
        <CustomText
          type="paragrafo"
          className={isMobileClasses({
            webClass: classes.description,
            mobileClass: classes.descriptionMobile,
          })}
        >
          Verteego Entertainment è il ramo della nostra azienda rivolto alla
          produzione audiovisiva. Da produzione cinematografiche a format TV,
          passando per videoclip musicali e contenuti pubblicitari, la
          creatività è il leitmotiv che ci accompagna durante l’intero processo
          produttivo.
        </CustomText>
        {isMobile && (
          <div className={classes.imgContainerMobile}>
            <StaticImage
              src="../../images/childrenAboutUs.png"
              alt="entertainment-content"
              className={classes.imgMobile}
            />
          </div>
        )}
        {items.allContentfulEntAboutUsLista.edges.map((item, idx) => (
          <div
            key={item.node.title}
            className={isMobileClasses({
              webClass: classes.itemRoot,
              mobileClass: classes.itemRootMobile,
            })}
          >
            <CustomText type="titoloH3Sezioni" className={classes.number}>
              {idx + 1 < 10 ? `0${idx + 1}.` : `${idx + 1}.`}
            </CustomText>
            <div className={classes.itemContent}>
              <CustomText
                type="titoloH3Sezioni"
                className={isMobileClasses({
                  webClass: classes.itemTitle,
                  mobileClass: classes.itemTitleMobile,
                })}
              >
                {item.node.title}
              </CustomText>
              <CustomText type="paragrafo">{item.node.subtitle}</CustomText>
            </div>
          </div>
        ))}
      </div>
      {!isMobile && (
        <div className={classes.sticky}>
          <StaticImage
            src="../../images/childrenAboutUs.png"
            alt="entertainment-content"
            className={classes.img}
          />
        </div>
      )}
    </div>
  );
};

export default AboutUsEntertainmentContent;
