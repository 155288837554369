import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useIsMobile from "../../hooks/useIsMobile";
import { StaticImage } from "gatsby-plugin-image";
import CustomText from "../CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  sectionRoot: {
    maxWidth: 1328,
    display: "grid",
    gridTemplateColumns: "45% 55%",
    alignItems: "center",
    justifyItems: "center",
    margin: "160px auto",
    padding: "0 16px;",
  },
  verteegoTitle: {
    lineHeight: "1",
    letterSpacing: -2.5,
    paddingTop: theme.spacing(2),
  },
  description: {
    paddingTop: theme.spacing(4),
  },
  tabletSectionRoot: {
    gridTemplateColumns: "1fr",
    margin: "80px auto",
    justifyItems: "left",
    maxWidth: 940,
  },
  tabletImg: {
    marginBottom: "32px",
  },
  mobileImg: {
    width: 140,
    marginBottom: 24,
  },
  mobileOur: {
    paddingBottom: 16,
  },
}));

const AboutUsLogoDescription = () => {
  const classes = useStyles();
  const { isMobileClasses } = useIsMobile();
  return (
    <section
      className={isMobileClasses({
        webClass: classes.sectionRoot,
        tabletClass: classes.tabletSectionRoot,
      })}
    >
      <StaticImage
        alt="logo-vortex"
        src="../../images/logo-vortex.png"
        className={isMobileClasses({
          tabletClass: classes.tabletImg,
          mobileClass: classes.mobileImg,
        })}
      />
      <div>
        <CustomText
          type="payoff"
          className={isMobileClasses({
            mobileClass: classes.mobileOur,
          })}
        >
          THIS IS OUR
        </CustomText>
        <CustomText
          type="titoloH2Sezioni"
          className={isMobileClasses({
            webClass: classes.verteegoTitle,
          })}
        >
          THIS IS WHAT VERTEEGO IS
        </CustomText>
        <CustomText
          type="paragrafo"
          className={isMobileClasses({
            webClass: classes.description,
          })}
        >
          Verteego è un’azienda che racchiude sotto il proprio marchio tre aree
          di business: nel settore cinematografico, pubblicitario e nel noleggio
          di attrezzature. Le nostre radici nel mondo del cinema ci hanno
          permesso di apprezzare ed assimilare la professionalità, la dedizione
          e le tecnica di questo incredibile mondo. Successivamente abbiamo
          deciso di portar il nostro know how nell’ambito pubblicitario
          superando i classici schemi ed allargando le barriere
          dell’immaginazione dei nostri clienti con uno sguardo sempre rivolto
          all’innovazione. La struttura di Verteego si completa con area del
          noleggio attrezzature e sale per la produzione e post produzione
          foto/videografica.
        </CustomText>
      </div>
    </section>
  );
};

export default AboutUsLogoDescription;
